import { toast } from 'sonner'
import { useCallback, useEffect, useState } from 'react'
import { Binoculars, BedDouble } from 'lucide-react'
import * as Sentry from '@sentry/astro'

import { buttonVariants } from '../../atoms/button/Button'

import type { Alert } from '@/lib/generateAlterts'
import { cn } from '@/lib/utils'

export const DemandAlert = ({ allProperties = false }: { allProperties?: boolean }) => {
  const [lastAlertTime, setLastAlertTime] = useState(Date.now())

  const fetchAndDisplayAlert = useCallback(async () => {
    try {
      const response = await fetch(`/api/alerts/?allproperties=${allProperties ? 'true' : 'false'}`)
      const alerts: Alert[] = await response.json()

      alerts.forEach((alert) => {
        toast(alert.message, {
          duration: 20000,
          icon: alert.type === 'viewing' ? <Binoculars /> : <BedDouble />,
          action: alert.propertyUri && (
            <a
              className={cn(
                buttonVariants({ size: 'xs', variant: 'default', shape: 'default' }),
                '!text-white'
              )}
              href={alert.propertyUri}
            >
              View
            </a>
          ),
        })
      })

      setLastAlertTime(Date.now())
    } catch (error) {
      Sentry.captureException('Error fetching demand alert', error)
    }
  }, [allProperties])

  useEffect(() => {
    let timeoutId: number | null = null

    const checkAndFetchAlert = () => {
      const now = Date.now()
      const timeSinceLastAlert = now - lastAlertTime
      const randomDelay = Math.floor(Math.random() * 30000 + 20000) // Random delay between 30-60 seconds

      if (timeSinceLastAlert >= randomDelay) {
        fetchAndDisplayAlert()
      }

      // Schedule the next check after the random delay
      timeoutId = window.setTimeout(checkAndFetchAlert, randomDelay)
    }

    // Start the initial check
    checkAndFetchAlert()

    return () => {
      // Clean up the timeout if the component unmounts
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [lastAlertTime, fetchAndDisplayAlert])

  return <></>
}
